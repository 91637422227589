import React from "react";
import PropTypes from "prop-types";
import { Container, Grid, Divider} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import FixedNavBar from "../components/FixedNavbar";
import Footer from "../components/Footer";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import BackgroundImage from 'gatsby-background-image';
import { Carousel } from 'react-responsive-carousel';

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  featuredpost,
  featuredimage,
  title,
  date,
  eventdate,
  helmet,
  carouselImages
}) => {
  const PostContent = contentComponent || Content;

  const featuredImage = getImage(featuredimage);

  return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} className="sectionHeaderCenter">
            <h1>
              {title} 
            </h1> 
            <Divider />
            <h4>
              {eventdate}
            </h4>
          </Grid>
          <Grid item xs={12} sm={12}>
            {carouselImages && (
              <>
                <Carousel showThumbs={false} showStatus={false} showIndicators={false} infiniteLoop={true}>
                  {carouselImages.map((img, ind) => (
                    <>
                      {img.carouselLink ?
                        <a href={img.carouselLink} target="_blank" rel="noreferrer">
                           <div>
                              <BackgroundImage
                                Tag="div"
                                fluid={img.carouselImage.childImageSharp.fluid}
                                className="newsItemCarouselWrapper"
                                
                                >
                              </BackgroundImage>
                              
                            
                            </div>
                          </a>
                          :
                          <div>
                          <BackgroundImage
                            Tag="div"
                            fluid={img.carouselImage.childImageSharp.fluid}
                            className="newsItemCarouselWrapper"
                            
                            >
                          </BackgroundImage>
                        </div>
                      }
                    </>
                  ))}
                </Carousel>
                </>
              )}
              {!carouselImages && (
                <>
                  {featuredimage ? (
                    <GatsbyImage placeholder="blurred" image={featuredImage} className="featuredImage" />
                  ) : 
                    <StaticImage src="../img/image-placeholder.jpg" alt=""  placeholder="blurred" className="featuredPlaceholderImage" />
                  }
                </>
              )}
            <div className="newsDescriptionWrapper bodyText">
              <PostContent content={content} />
            </div>
            <Divider className="divider" style={{marginBottom:"24px"}}/>
          </Grid>    
          <Grid item xs={12} align="center">
            <Link to="/news" >
              <button className="primaryButton">
                Back to News
              </button>
            </Link>
          </Grid>
        </Grid>
    </>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  featuredimage: PropTypes.string,
  featuredpost: PropTypes.bool,
  title: PropTypes.string,
  date: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  console.log(post);

  return (
    <>
      <FixedNavBar />
      <section className="single-news-item">
        <Container maxWidth="md">
          <BlogPostTemplate
            content={post.html}
            contentComponent={HTMLContent}
            description={post.frontmatter.description}
            helmet={
              <Helmet titleTemplate="%s | News">
                <title>{`${post.frontmatter.title}`}</title>
                <meta
                  name="description"
                  content={`${post.frontmatter.description}`}
                />
              </Helmet>
            }
            featuredpost={post.frontmatter.featuredpost}
            featuredimage={post.frontmatter.featuredimage}
            tags={post.frontmatter.tags}
            title={post.frontmatter.title}
            date={post.frontmatter.date}
            eventdate={post.frontmatter.eventdate}
            carouselImages={post.frontmatter.carouselImages}
          />
        </Container>
      </section>
      <Footer />
    </>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        eventdate(formatString: "DD MMMM YYYY")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              quality: 100
            )
          }
        }
        carouselImages {
          carouselLink
          carouselImage {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
